import React from 'react';
import logo from './imgs/logo_border.png';
import './TopMenu.css';

const TopMenu = () => {
  return (
    <header className="header-bar">
      <img src={logo} alt="Banner" className="banner-image" />
    </header>
  );
};

export default TopMenu;

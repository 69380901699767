import React from 'react';
import './App.v3.css';
import {Row, Col, Card } from "antd";
import 'antd/dist/antd.css';
import TopMenu from "./TopMenu";
import estesBathroom from "./imgs/estes_bathroom.jpg";
import {ImgGallery} from "./ImgGallery";
import {ContactCard} from "./ContactCard";

function App() {

  return (
    <div className="App">
        <Row> 
            <Col xs={0} md={6}></Col>
            <Col xs={24} md={12}>
                <TopMenu/>
            </Col>
            <Col xs={0} md={6}></Col>
        </Row>
        <div>
            <Row style={{marginBottom:10}}> 
                <Col xs={0} md={6}></Col>
                <Col xs={24} md={12}>
                    <Card className="SubPanel MainContent" style={{marginTop:10, backgroundColor: "#BFD8BD"}}>
                        <h1 style={{margin:0}} className="card-header"> Property Innovationz, LLC </h1>
                        <h2 style={{margin:0}} className="card-subheader">We specialize in custom residential bathroom renovations.</h2>
                    </Card>
                </Col>
                <Col xs={0} md={6}></Col>
            </Row>
            <Row>
                <Col xs={0} md={6}></Col>
                <Col xs={24} md={12}>
                    <ImgGallery/>
                </Col>
                <Col xs={0} md={6}></Col>
            </Row>
            <Row>
                <Col xs={0} md={6}></Col>
                <Col xs={24} md={12}>
                    <ContactCard/>
                </Col>
                <Col xs={0} md={6}></Col>
            </Row>   
        </div>
        
    </div>
  );
}

export default App;

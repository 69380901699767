import React from 'react';
import { Card } from 'antd';
import logo from "./imgs/logo_border.png";
import './App.v3.css';


function ContactCard() {
    return(
        <div>
            <Card className='MainContent' style={{marginTop: 10, marginBottom: 10}}>
                <h2 style={{margin:0}} className='contact-header'>Frank Kveton</h2>
                <h3 className='contact-subheader'>Lead Estimator and Installer</h3>
                <h3 className='contact-subheader' style={{padding:5, margin:0}}>(303) 945-1894</h3>
                <h3 className='contact-subheader' style={{padding:5, margin:0}}>frank@propertyinnovationz.com</h3>
            </Card >
        </div>
    )
}

export { ContactCard };

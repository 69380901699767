import React from 'react';
import {Card, Image} from "antd";
// @ts-ignore
import ImageGallery from 'react-image-gallery';
import amy from "./imgs/before_after/amy_words.jpg";
import lisa from "./imgs/before_after/lisa_words.jpg";
import tracie2 from "./imgs/before_after/tracie_words.jpg";
import andrewShower from "./imgs/before_after/andrew_shower_words.jpg";
import "react-image-gallery/styles/css/image-gallery.css";
import './App.v3.css';



function ImgGallery() {
    const height = 500;
    const width = 500;

    const images = [
      {
        original: tracie2,
        height: height,
        width: width,
      },
      {
        original: amy,
        height: height,
        width: width,
      },
      {
        original: andrewShower,
        height: height,
        width: width,
      },
      {
        original: lisa,
        height: height,
        width: width,
      }
    ];


    return(
        <div>
            <Card className='MainContent' style={{backgroundColor:"#BFD8BD"}}>
                <ImageGallery 
                items={images}
                showThumbnails={false}
                showPlayButton={false}
            />
            </Card>
        </div>
    )
}

export { ImgGallery };
